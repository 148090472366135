// I apologise in advance for how messy and unorganised this is.
// If you need any help please poke me.
// RL

import "core-js/stable";
import { h, app } from "hyperapp";
import building from "../icons/ui/building.svg";
import done from "../icons/done.svg";
import finishing from "../icons/ui/finishing.svg";
import preparing from "../icons/ui/preparing.svg";
import tick from "../icons/tick.svg";
import transferring from "../icons/ui/transferring.svg";

const icons = {
  building,
  finishing,
  preparing,
  tick,
  transferring
};

const STAGES = [
  {
    identifier: "preparing",
    name: "Preparing"
  },
  {
    identifier: "building",
    name: "Building"
  },
  {
    identifier: "transferring",
    name: "Transferring"
  },
  {
    identifier: "finishing",
    name: "Finishing"
  }
];

const Border = ({ status }) => <div className={`ui-stages__border ui-stages__border--${status}`} />;

const Connector = ({ from, to }) => {
  const modifier = from === to ? from : `${from}-to-${to}`;
  return <div className={`ui-stages__connector ui-stages__connector--${modifier}`} />;
};

function statusIcon(status) {
  switch (status) {
    case "complete":
      return <img alt="complete" className="ui-steps__icon" src={tick} />;
    case "running":
      return <div className="ui-spinner" />;
    default:
      return <div className="ui-dots" />;
  }
}

const Indicator = ({ status }) => {
  const icon = statusIcon(status);
  return <div className="ui-steps__indicator">{icon}</div>;
};

const Stage = ({ active, icon, name, status }) => {
  const classNames = [`ui-stages__circle ui-stages__circle--${status}`];

  if (active) {
    classNames.push("ui-stages__circle--selected");
  }

  return (
    <div className={classNames.join(" ")}>
      <img alt="" className="ui-stages__icon " src={icon} role="presentation" />
      <div className="ui-stages__text">{name}</div>
    </div>
  );
};

const ListItem = ({ text }) => <li className="ui-steps__list-item">{text}</li>;

const Transfer = ({ item }) => {
  const after = `/website/${item.text}`;
  const before = `build/${item.text}`;
  return (
    <li className="ui-steps__list-item">
      Uploading <span>{before}</span>
    </li>
  );
};

const List = ({ list, type }) => {
  let items;

  if (type === "transfer") {
    items = list.map(item => <Transfer item={item} key={item.text} />);
  } else {
    items = list.map(item => <ListItem key={item.text} text={item.text} />);
  }

  return (
    <ul
      className="ui-steps__list"
      onupdate={element => {
        element.scrollTop = element.scrollHeight;
      }}
    >
      {items}
    </ul>
  );
};

const Progress = ({ max, value }) => {
  const percentage = (value / max) * 100;
  const width = `${percentage}%`;

  return (
    <div className="ui-steps__progress">
      <div className="ui-steps__progress-fill" style={{ width: width }} />
    </div>
  );
};

const Step = ({ list, name, status, type }) => {
  const complete = list.filter(item => item.complete);
  return (
    <div className="ui-steps__item">
      <div className="ui-steps__header">
        <Indicator status={status} />
        <div className="ui-steps__text">{name}</div>
        {list.length > 0 &&
          status === "running" && <Progress max={list.length} value={complete.length} />}
      </div>
      {complete.length > 0 && status === "running" && <List list={complete} type={type} />}
    </div>
  );
};

const actions = {
  next: () => state => {
    const steps = state.stages[state.stage];
    const current = steps[state.step];
    const incompleteIndex = current.list.findIndex(item => !item.complete);

    if (incompleteIndex === -1) {
      if (current.status === "complete") {
        const nextStep = steps[state.step + 1];

        if (nextStep) {
          return {
            step: state.step + 1
          };
        } else {
          const index = STAGES.findIndex(stage => stage.identifier === state.stage);
          const nextStage = STAGES[index + 1];

          if (nextStage) {
            const updatedSteps = [...state.stages[nextStage.identifier]];

            updatedSteps[0] = {
              ...updatedSteps[0],
              status: "running"
            };

            return {
              stage: nextStage.identifier,
              step: 0,
              stages: {
                ...state.stages,
                [nextStage.identifier]: updatedSteps
              }
            };
          } else {
            return {
              done: true
            };
          }
        }
      } else if (current.status === "running") {
        const nextStep = steps[state.step + 1];
        const updatedSteps = [...steps];

        updatedSteps[state.step] = {
          ...current,
          status: "complete"
        };

        if (nextStep) {
          updatedSteps[state.step + 1] = {
            ...nextStep,
            status: "running"
          };
        }

        return {
          stages: {
            ...state.stages,
            [state.stage]: updatedSteps
          }
        };
      } else {
        const updatedSteps = [...steps];

        updatedSteps[state.step] = {
          ...current,
          status: "running"
        };

        return {
          stages: {
            ...state.stages,
            [state.stage]: updatedSteps
          }
        };
      }
    } else {
      const updatedSteps = [...steps];
      const updatedList = [...current.list];

      updatedList[incompleteIndex] = {
        ...updatedList[incompleteIndex],
        complete: true
      };

      updatedSteps[state.step] = {
        ...updatedSteps[state.step],
        list: updatedList
      };

      return {
        stages: {
          ...state.stages,
          [state.stage]: updatedSteps
        }
      };
    }
  },
  runAgain: actions => {
    startAgain(actions);
    return { ...DEFAULT_STATE };
  }
};

const start = actions => {
  interval = window.setInterval(() => {
    actions.next();
  }, 100);
};

const stop = () => {
  if (typeof interval === "number") {
    window.clearInterval(interval);
    interval = null;
  }
};

const startAgain = actions => {
  stop();
  start(actions);
};

let startAgainButton;

const DEFAULT_STATE = {
  done: false,
  stage: "preparing",
  step: 0,
  stages: {
    preparing: [
      {
        list: [],
        name: "Waiting for an available deployment slot",
        status: "pending",
        type: "standard"
      },
      {
        list: [
          { complete: false, text: "Checking access to repository" },
          { complete: false, text: "Checking start and end revisions are valid" },
          { complete: false, text: "Checking connection to server Production" }
        ],
        name: "Performing pre-deployment checks",
        status: "pending",
        type: "list"
      },
      {
        list: [
          {
            complete: false,
            text: "Updating repository from https://github.com/deployhq/example.git"
          },
          { complete: false, text: "Getting information for start commit" },
          { complete: false, text: "Getting information for end commit ab52df" }
        ],
        name: "Preparing repository for deployment",
        status: "pending",
        type: "list"
      }
    ],
    building: [
      {
        list: [],
        name: "Installing Dependencies",
        status: "pending",
        type: "standard"
      }
    ],
    transferring: [
      {
        list: [
          { complete: false, text: "package.json" },
          { complete: false, text: "app/assets/config/manifest.js" },
          { complete: false, text: "app/assets/javascripts/application.js" },
          { complete: false, text: "app/assets/javascripts/cable.js" },
          { complete: false, text: "app/assets/stylesheets/application.css" },
          { complete: false, text: "app/channels/application_cable/channel.rb" },
          { complete: false, text: "app/channels/application_cable/connection.rb" },
          { complete: false, text: "app/controllers/application_controller.rb" },
          { complete: false, text: "app/helpers/application_helper.rb" },
          { complete: false, text: "app/jobs/application_job.rb" },
          { complete: false, text: "app/mailers/application_mailer.rb" },
          { complete: false, text: "app/models/application_record.rb" },
          { complete: false, text: "app/views/layouts/application.html.erb" },
          { complete: false, text: "app/views/layouts/mailer.html.erb" },
          { complete: false, text: "app/views/layouts/mailer.text.erb" },
          { complete: false, text: "bin/bundle" },
          { complete: false, text: "bin/rails" },
          { complete: false, text: "bin/rake" },
          { complete: false, text: "bin/setup" },
          { complete: false, text: "bin/update" },
          { complete: false, text: "bin/yarn" },
          { complete: false, text: "config/routes.rb" },
          { complete: false, text: "config/application.rb" },
          { complete: false, text: "config/environment.rb" },
          { complete: false, text: "config/cable.yml" },
          { complete: false, text: "config/puma.rb" },
          { complete: false, text: "config/spring.rb" },
          { complete: false, text: "config/storage.yml" },
          { complete: false, text: "config/environments/development.rb" },
          { complete: false, text: "config/environments/production.rb" },
          { complete: false, text: "config/environments/test.rb" },
          { complete: false, text: "config/initializers/application_controller_renderer.rb" },
          { complete: false, text: "config/initializers/assets.rb" },
          { complete: false, text: "config/initializers/backtrace_silencers.rb" },
          { complete: false, text: "config/initializers/content_security_policy.rb" },
          { complete: false, text: "config/initializers/cookies_serializer.rb" },
          { complete: false, text: "config/initializers/cors.rb" },
          { complete: false, text: "config/initializers/filter_parameter_logging.rb" },
          { complete: false, text: "config/initializers/inflections.rb" },
          { complete: false, text: "config/initializers/mime_types.rb" },
          { complete: false, text: "config/initializers/new_framework_defaults_5_2.rb" },
          { complete: false, text: "config/initializers/wrap_parameters.rb" },
          { complete: false, text: "config/locales/en.yml" },
          { complete: false, text: "config/master.key" },
          { complete: false, text: "config/boot.rb" },
          { complete: false, text: "config/database.yml" },
          { complete: false, text: "db/seeds.rb" },
          { complete: false, text: "public/404.html" },
          { complete: false, text: "public/422.html" },
          { complete: false, text: "public/500.html" },
          { complete: false, text: "public/apple-touch-icon-precomposed.png" },
          { complete: false, text: "public/apple-touch-icon.png" },
          { complete: false, text: "public/favicon.ico" },
          { complete: false, text: "public/robots.txt" }
        ],
        name: "Transferring files",
        status: "pending",
        type: "transfer"
      },
      {
        list: [{ complete: false, text: "config/database.yml" }],
        name: "Uploading config files",
        status: "pending",
        type: "transfer"
      }
    ],
    finishing: [
      {
        list: [],
        name: "Sending notification via Slack",
        status: "pending",
        type: "standard"
      },
      {
        list: [],
        name: "Delivering webhook notifications",
        status: "pending",
        type: "standard"
      },
      {
        list: [],
        name: "Sending email notifications",
        status: "pending",
        type: "standard"
      }
    ]
  }
};

const state = { ...DEFAULT_STATE };

const startBorderStatus = index => (index === 0 ? "running" : "complete");

const connectorStatuses = (start, end, index, done) => {
  let from = "pending";
  let to = "pending";

  if (start === index) {
    from = "pending";
  } else if (start < index) {
    from = "complete";
  }

  if (end === index) {
    to = "running";
  } else if (end < index) {
    to = "complete";
  }

  if (end === 3 && done) {
    to = "complete";
  }

  return {
    from,
    to
  };
};

const lastConnectorStatus = (index, done) => {
  if (index === 3) {
    return done ? "complete" : "running";
  }

  return "pending";
};

const stageStatus = (itemIndex, activeIndex, done) => {
  if (itemIndex === 3 && done) {
    return "complete";
  }

  if (itemIndex === activeIndex) {
    return "running";
  } else if (itemIndex > activeIndex) {
    return "pending";
  }
  return "complete";
};

let interval;

const Completed = ({ runAgain }) => {
  return (
    <div className="deployment-completed">
      <div className="deployment-completed__circle">
        <img
          className="deployment-completed__icon"
          draggable={false}
          role="presentation"
          src={done}
        />
      </div>
      <p className="deployment-completed__text deployment-completed__text--title">
        Your deployment has completed successfully!
      </p>
      <p className="deployment-completed__text deployment-completed__text--paragraph">
        Click on any of the stages above to view deployment logs
      </p>
      <button className="deployment-completed__button" onclick={runAgain} type="button">
        Run the deployment again
      </button>
    </div>
  );
};

const view = (state, actions) => {
  const { done, stage, stages } = state;
  const { runAgain } = actions;
  const steps = stages[stage];
  const index = STAGES.findIndex(({ identifier }) => identifier === stage);

  return (
    <div
      className="ui__container"
      oncreate={() => {
        startAgainButton = document.querySelector(".js-start-again");

        startAgainButton.addEventListener(
          "click",
          () => {
            runAgain(actions);
          },
          false
        );

        start(actions);
      }}
      onupdate={() => {
        if (state.done) {
          stop();
        }
      }}
    >
      <div className="ui-stages">
        <Border status="complete" />
        <Connector from="complete" to={startBorderStatus(index)} />
        <Stage
          active={index === 0}
          name="Preparing"
          icon={icons["preparing"]}
          status={stageStatus(0, index)}
        />
        <Connector {...connectorStatuses(0, 1, index, done)} />
        <Stage
          active={index === 1}
          name="Building"
          icon={icons["building"]}
          status={stageStatus(1, index, done)}
        />
        <Connector {...connectorStatuses(1, 2, index, done)} />
        <Stage
          active={index === 2}
          name="Transferring"
          icon={icons["transferring"]}
          status={stageStatus(2, index, done)}
        />
        <Connector {...connectorStatuses(2, 3, index, done)} />
        <Stage
          active={index === 3}
          name="Finishing"
          icon={icons["finishing"]}
          status={stageStatus(3, index, done)}
        />
        <Connector from={lastConnectorStatus(index, done)} to={done ? "complete" : "pending"} />
        <Border status={done ? "complete" : "pending"} />
      </div>
      {done ? (
        <Completed runAgain={() => runAgain(actions)} />
      ) : (
        <div className="ui-steps">
          {steps.map(step => (
            <Step {...step} />
          ))}
        </div>
      )}
    </div>
  );
};

const animation = document.querySelector(".js-ui");

animation && app(state, actions, view, animation);

if (false) {
  console.log("False");
}
